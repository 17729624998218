body {
  color: #333;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

header, footer {
  text-align: center;
  background-color: #eee;
  padding: 1rem 0;
}

main {
  padding: 1rem;
}
/*# sourceMappingURL=index.83612f54.css.map */
