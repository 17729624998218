$body-color: #333;

body {
    font-family: Arial, sans-serif;
    color: $body-color;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header, footer {
    background-color: #eee;
    text-align: center;
    padding: 1rem 0;
}

main {
    padding: 1rem;
}
